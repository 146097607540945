.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .timeline::after {
    content: '';
    position: absolute;
    width: 3px;
    background-color: #777;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
  }
  
  .container {
    padding: 8px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
  }
  
  .container::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    right: -6px;
    background-color: #ddd;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
    margin-top: 5%;
  }
  .time{
    position: absolute;
    right: -15%;
    bottom: 58%;
    font-size: 20px;
    color: rgb(40, 39, 39);
  }
  
  .time-left{
    position: absolute;
    left: -15%;
    bottom: 58%;
    font-size: 20px;
    color: rgb(40, 39, 39);
  }
  
  .left {
    left: 0;
  }
  
  .right {
    left: 50%;
  }
  .container .date {
    position: absolute;
    display: inline-block;
    top: calc(50% - 8px);
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: 1;
  }
  
  .container.left .date {
    right: -226px;
  }
  
  .container.right .date {
    left: -226px;
  }
  
  .left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid #fff;
    border-width: 20px 0 20px 20px;
    border-color: transparent transparent transparent #fff;
    
  }
  
  .right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid #fff;
    border-width: 20px 20px 20px 0;
    border-color: transparent #fff transparent transparent;
  }
  
  .right::after {
    left: -8px;
  }
  
  .content {
    padding: 20px 30px;
    background-color:#fff;
    position: relative;
    border-radius: 6px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    height:90px;
  }
  
  @media screen and (max-width: 600px) {
    .timeline::after {
      left: 31px;
    }
  
    .container {
      width: 100%;
      padding-left: 70px;
      padding-right: 25px;
    }
  
    .container::before {
      left: 60px;
      border: medium solid white;
      border-width: 10px 10px 10px 0;
      border-color: transparent white transparent transparent;
    }
  
    .left::after,
    .right::after {
      left: 15px;
    }
  
    .right {
      left: 0%;
    }
  }
  